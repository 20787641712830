@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins-SemiBold'),url('./Assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'),url('./Assets/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'),url('./Assets/fonts/Inter-Regular.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: Poppins-SemiBold, Poppins, Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

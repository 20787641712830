.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 0 1.2rem;
  height: 55px;
}

.logo {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.mobile-menu {
  display: block;
}

.user-menu {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #fff;
}

.user-menu:hover {
  color: #1C4792;
}